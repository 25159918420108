import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	private sessionIdSubject$: BehaviorSubject<string | null>;
	sessionId$: Observable<string | null>;

	constructor() {
		this.sessionIdSubject$ = new BehaviorSubject<string | null>(null);
		this.sessionId$ = this.sessionIdSubject$.asObservable();
	}

	subscribeToSessionId(): void {
		window.addEventListener('message', this.receiveMessage.bind(this));
	}

	private receiveMessage(event: MessageEvent): void {
		// Check if the message contains sessionId
		if (event.data && event.data['type'] === 'trovata-session-id') {
			// Process sessionId
			this.sessionIdSubject$.next(event.data['sessionId']);
		}
	}
}
