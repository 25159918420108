import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionService } from '../services/session.service';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	private sessionId: string | null;

	constructor(private sessionsService: SessionService) {
		this.sessionId = null;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const secureReq: HttpRequest<any> = req.clone({
			url: req.url.replace('http://', 'https://'),
		});
		const allowWithoutAuth: boolean = this.allowWithoutAuth(secureReq.url);

		if (allowWithoutAuth) {
			return next.handle(secureReq.clone());
		}
		if (this.isApi(secureReq.url)) {
			if (this.sessionId) {
				const clonedReq: HttpRequest<any> = secureReq.clone({
					headers: secureReq.headers.set('Authorization', `Bearer ${this.sessionId}`),
				});
				return this.handleClonedRequest(clonedReq, next);
			} else {
				return new Observable<HttpEvent<any>>(observer => {
					this.getSessionId()
						.then((sessionId: string) => {
							this.sessionId = sessionId;
							const clonedReq: HttpRequest<any> = secureReq.clone({
								headers: secureReq.headers.set('Authorization', `Bearer ${this.sessionId}`),
							});
							this.handleClonedRequest(clonedReq, next).subscribe(observer);
						})
						.catch((error: Error) => {
							observer.error(error);
						});
				});
			}
		} else {
			return next.handle(req);
		}
	}

	private handleClonedRequest(clonedReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(clonedReq).pipe(
			tap(
				() => {},
				async (httpError: HttpErrorResponse) => {
					// const route: string = document.location.href.split(document.location.origin)[1];

					if (
						((httpError && httpError.status && httpError.status === 401) ||
							(httpError && httpError.error && httpError.error.status && httpError.error.status === 401)) &&
						// TODO remove this when change password failure is not 401: https://trovata.atlassian.net/browse/CP-274
						!(httpError && httpError.url?.includes('/auth/password'))
					) {
						// this.store.dispatch(new Logout());
					}

					if (httpError && httpError.error && httpError.error.message) {
						return throwError(() => httpError.error.message);
					} else if (httpError && httpError.message) {
						return throwError(() => httpError.message);
					} else {
						return throwError(() => 'Something bad happened; please try again later.');
					}
				}
			)
		);
	}

	private async getSessionId(): Promise<string> {
		return new Promise((resolve, reject) => {
			const sessionIdTimeout: any = setTimeout(() => {
				reject(new Error('Timed out waiting for session ID'));
			}, 60000);
			this.sessionsService.sessionId$.subscribe(sessionId => {
				if (sessionId) {
					clearTimeout(sessionIdTimeout);
					resolve(sessionId);
				}
			});
		});
	}

	private isApi(url: string): boolean {
		if (url.includes(environment.trovataApi)) {
			return true;
		} else {
			return false;
		}
	}

	private allowWithoutAuth(url: string): boolean {
		if (
			false
			// url.includes('/auth/token')
		) {
			return true;
		} else {
			return false;
		}
	}
}
