import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SessionService } from './core/services/session.service';
import { Session, SessionInitializationResourceType } from './shared/models/identity.model';
import { firstValueFrom } from 'rxjs';
import { IdentityService } from './shared/services/identity.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityText } from './core/models/utility-text';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	title: string = 'trovata-angular-app-lite';

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private sessionService: SessionService,
		private identityService: IdentityService
	) {
		this.sessionService.subscribeToSessionId();
	}

	async ngOnInit(): Promise<void> {
		try {
			const session: Session = await firstValueFrom(this.identityService.getSession());

			if (session.initializationParameters && session.initializationParameters.resourceType === SessionInitializationResourceType.connection) {
				this.router.navigate(['/main-menu/connections'], {
					queryParams: {
						connectionId: session.initializationParameters.resourceId,
						action: session.initializationParameters.action,
					},
				});
			}
		} catch (error) {
			this.snackBar.open(UtilityText.somethingWrongMsg, undefined, {
				duration: 6000,
			});
			throw error;
		}
	}
}
