import { Routes } from '@angular/router';

export const routes: Routes = [
	{
		path: '',
		redirectTo: '/main-menu',
		pathMatch: 'full',
	},
	{
		path: 'main-menu',
		loadChildren: () => import('./core/components/main-menu/main-menu-routes').then(routes => routes.mainMenuRoute),
	},
];
